<template>
    <div class="bbb">
        <van-nav-bar
        title="新闻"
        left-arrow
        @click-left="onClickLeft"
        />
        <div id="Interrogation" class=" time_slot">
            <div v-if="id == '0'">
                <h2>「优质服务我们在行动」多形式健康宣教，打造门诊服务最美名片</h2>
                <div>
                    <img style="width: 100%;" src="./../assets//images/info/info00.jpeg" alt="">
                </div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;近日，解放军总医院第七医学中心门诊部综合治疗室护理团队定时定点在患者集中的候诊区开展健康科普宣教活动，针对诊前、诊中、诊后不同时期，利用就诊间歇为患者介绍就诊须知、常见疾病预防、慢病管理、各种特殊检查前准备等各方面的科普知识。候诊人员一边听讲，一边候诊，有效缓解了等待产生的焦虑情绪。这是门诊部护理团队传承弘扬“两高”精神，积极开展门诊服务质量提升活动的一个缩影。
                </div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;连日来，门诊部综合治疗室按照中心传承弘扬总医院优良传统教育部署要求，牢牢把握中心高质量发展核心理念，积极开展服务质量整改提升季活动，竭诚为患服务，切实以优质服务推动护理各项工作高质量发展，患者就医体验持续改善，获得感有效提升。
                </div>
                <div>
                    <img style="width: 100%;" src="./../assets//images/info/info01.jpeg" alt="">
                </div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;门诊部综合治疗室护士长介绍，以前门诊各诊区开诊时，患者经常争先恐后涌向分诊台，“护士，我是在这看吗”“护士，这个怎么报到啊”“护士，抽血室在什么位置”……一边是护理人员应接不暇，多次重复，一边是部分患者不能及时得到细致答复。针对这一现象，门诊部综合治疗室主动作为，积极探索创新护理服务模式，着力解决开诊前患者聚集咨询环节，认真梳理工作中遇到的高频次问题，在开诊前十分钟进行集中宣教，宣教内容包括诊区出诊科室介绍，患者报到方法，治疗区、检查区位置，预约挂号方式等。护理团队以内科分诊作为试点单元，多次组织岗前培训和试讲演练，本着“成熟一个推行一个”的原则，逐步以点带面进行推广，目前门诊部负责的五个诊区已全面开展此项服务。
                </div>
                <div>
                    <img style="width: 100%;" src="./../assets//images/info/info02.jpeg" alt="">
                </div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;为方便患者了解更多专科病预防及治疗知识，门诊部综合治疗室制作图文并茂、易于理解的宣教手册供候诊患者自行取阅，并充分利用多媒体资源播放专科宣教视频，实现了诊前、诊中健康宣教全面覆盖。下一步，门诊部综合治疗室还将根据患者和家属实际需求，动态调整、持续更新完善科普内容，不断推动护理服务人文化、个性化、标准化。系列健康宣教活动拉近了护患距离，患者满意度显著提升。
                </div>
            </div>
            <div v-else-if="id == '1'">
                <h2>「微动态」第三医学中心门诊部扎实开展“六个一”健康宣教系列活动</h2>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;为深入贯彻落实党的二十大精神，推进中心高质量发展，深化门诊内涵质量建设，充分发挥门诊医护人员的专科水平，提升患者就医体验，近日，解放军总医院第三医学中心门诊部扎实开展“六个一”健康宣教系列活动。
                </div>
                <div>
                    <img style="width: 100%;" src="./../assets//images/info/info10.jpeg" alt="">
                </div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;设计一组海报。门诊部每位医护人员充分利用所学专业知识，制作出一个个选题新颖、生动形象、通俗易懂的健康宣教海报，内容涉及传染病、常见病、养生知识等。通过海报制作，丰富了大家的健康知识，提升了护士健康宣教能力，为优质服务患者奠定基础
                </div>
                <div>
                    <img style="width: 100%;" src="./../assets//images/info/info11.jpeg" alt="">
                </div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;开展一场评选。门诊部开展了“科普促健康、携手向未来”门诊健康宣教海报评选活动。门诊部全体医护人员及综合治疗室护理人员共同参与，为25幅参赛作品投票，最终评选出一、二、三等奖及优秀奖，并为获奖者颁发证书。此次活动在树立中心门诊良好形象的同时，展现了医护人员的智慧，激发了医护人员的科普创作热情。
                </div>
                <div>
                    <img style="width: 100%;" src="./../assets//images/info/info12.jpeg" alt="">
                </div>
                <div class="text">
                    　&nbsp;&nbsp;&nbsp;更换一期宣传栏。所有获奖作品制作成健康宣教海报在门诊楼各个宣传栏进行展示，生动直观地让患者了解专科特色、防治知识，帮助患者减少在候诊过程中的焦虑、烦躁心理。根据季节变换、诊区特点及各个宣传活动的需要，定期更换宣传栏内容。
                </div>
                <div class="text">
                    　&nbsp;&nbsp;&nbsp;建立一个专栏。通过信息化手段，在“解放军总医院第三医学中心”公众号，增设科室介绍子栏目、科室出诊专家信息，提升各科室专科特色宣传力度，提高中心公众形象。每个科室形成专属二维码，设置在每张海报右下角，患者可直接扫码，了解科室专家信息并可链接到中心公众号，方便患者就诊。
                </div>
            </div>
            <footer_/>
        </div>
    </div>
  </template>
  <script>
  import footer_ from '../components/footer.vue'
  export default {
    components: {
      footer_,
    },
    data() {
      return {
       
      }
    },
    methods: {
        onClickLeft(){
            this.$router.back(-1)
        },
        loadList(){
        
        },
    },
    created() {
        this.id = this.$route.query.id
    },
    mounted(){
   

    }
  }
  </script>
  <style  lang="less">
    .time_slot{
        font-family: SimHei,Source Han Sans Traditional Chinese normal,San Francisco Display Regular,STHeiti;
    }
    .text-title{
        font-weight: 700;
    }
    .text{
        font-size: 14px;
    }
  </style>